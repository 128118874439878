import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const setAccessibleRoutes = (user) => {
  const accessibleRoutes = user?.user_group?.accessible_menus
  ? user.user_group.accessible_menus
  : {};
  if (user?.user_group?.permissions) {
    const keys = Object.keys(user?.user_group?.permissions);
    if (keys.length) {
      keys.map(val => {
        if (val.indexOf('users') > -1 && user?.user_group?.permissions[val]) {
          accessibleRoutes.users = true
        }
        if (val.indexOf('userGroups') > -1  && user?.user_group?.permissions[val]) {
          accessibleRoutes.userGroups = true
        }
      })
    }
  }
  return accessibleRoutes;
}

let user = {};
let accessibleRoutes = {};
if (localStorage.getItem('user')) {
  try {
    user = JSON.parse(localStorage.getItem('user'));
    accessibleRoutes = setAccessibleRoutes(user);
  } catch (error) {
    user = {}
  }
}

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user,
    accessibleRoutes,
    footer_info: null,
    errorBrowser: null,
    siteId: null,
    isHeaderMenuFull: false,
    time_zone: null,
    // currency: null,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, data) {
      state.status = 'success'
      state.token = data.token
      state.user = data.user
      const accessibleRoutes = setAccessibleRoutes(data.user)
      state.accessibleRoutes = Object.assign({}, state.accessibleRoutes, accessibleRoutes);
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    setFooterInfo(state, data) {
      state.footer_info = data;
    },
    setErrorBrowser(state, errorBrowser) {
      state.errorBrowser = errorBrowser;
    },
    clearErrorBrowser(state) {
      state.errorBrowser = null;
    },
    setSiteId(state, markerId) {
      state.siteId = markerId;
    },
    setTimeZone(state, timeZone) {
      state.time_zone = timeZone;
    },
    // setSiteCurrency(state, currency) {
    //   state.currency = currency;
    // },
    setHeaderMenu(state, menu) {
      state.isHeaderMenuFull = menu;
    }
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve) => {
        localStorage.setItem('token', data.token)
        localStorage.setItem('user', JSON.stringify(data.user));
        commit('auth_success', data)
        resolve(data.user)
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user')
        commit('logout')
        resolve()
      })
    },
    setFooterInfo({commit}, data) {
      commit('setFooterInfo', data)
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
    accessibleRoutes: state => state.accessibleRoutes,
    siteId: state => state.siteId,
    time_zone: state => state.time_zone,
    // currency: state => state.currency,
    isHeaderMenuFull: state => state.isHeaderMenuFull,
  }
})

import HTTP_SERVICE from './http-service';

export default {
    getListUsers(params) {
        return HTTP_SERVICE.get('/users' + (params ?? ''));
    },

    storeUser(data) {
        return HTTP_SERVICE.post('/users', data);
    },

    getListSitesAndRoles() {
        return HTTP_SERVICE.get('/get-site-and-role');
    },
    
    deleteUser(id) {
        return HTTP_SERVICE.delete(`/users/${id}`);
    },

    getUser(id) {
        return HTTP_SERVICE.get(`/users/${id}`);
    },

    updateUser(id, data) {
        return HTTP_SERVICE.put(`/users/${id}`, data);
    },

    UpdateUserStatus(id, data) {
        return HTTP_SERVICE.put(`/update-user-status/${id}`, data);
    },
}
<template>
  <v-app class="app-wrapper" :dark="$vuetify.theme.dark">
    <BarMenu v-if="$store.state && $store.state.user && $store.state.token && !noAuthRoutes.includes($route.name)" 
      :switchIcon="switchIcon" 
      @toggle-theme-mode="toggleThemeMode">
    </BarMenu>
    <v-main style="padding: 0">
      <v-container fluid class="p-0 h-100">
        <router-view />
        <BrowserWarning v-if="isShowWarning" />
        <div class="clearfix"></div>
      </v-container>
    </v-main>
  </v-app>
</template>

<style src="./assets/css/styles.css"></style>

<script>
import BarMenu from "@/components/BarMenu.vue";
import { noAuthRoutes } from '@/constants';
import BrowserWarning from "@/components/BrowserWarning.vue";

export default {
  name: "App",
  data() {
    return {
      noAuthRoutes: noAuthRoutes,
      switchIcon: false,
      isShowWarning: false,
    };
  },
  components: { BarMenu, BrowserWarning, },
  watch:{
    "$route.name": function(){
      setTimeout(()=>{
        if(this.$route.name !== "Login" && this.$route.name !== "Forgot Password"){
          this.isShowWarning = true;
        }
      }, 500)
    }
  },
  created() {
    const savedThemeMode = localStorage.getItem('themeMode');
    if (savedThemeMode !== null) {
      this.$vuetify.theme.dark = JSON.parse(savedThemeMode);
      if(this.$vuetify.theme.dark) {
        this.switchIcon = true;
      } else {
        this.switchIcon = false;
      }
    }
  },
  methods: {
    toggleThemeMode(newValue) {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.switchIcon = newValue;
      localStorage.setItem('themeMode', this.$vuetify.theme.dark);
    }
  },
};
</script>

import HTTP_SERVICE from './http-service';

export default {
    getListInventories(data) {
        return HTTP_SERVICE.get('/inventories', {params: data});
    },
    storeInventory(data) {
        return HTTP_SERVICE.post('/inventories', data);
    },
    deleteInventory(data) {
        return HTTP_SERVICE.delete(`/inventories`, data);
    },
}
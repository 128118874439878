import moment from "moment";

export default {
  install: (app) => {
    app.mixin({
      data: function () {
        return {
          DATE_TIME_HOUR_FORMAT_MOMENT: "YYYY-MM-DD HH:mm",
          FULL_DATE_TIME_FORMAT_MOMENT: "YYYY-MM-DD HH:mm:ss",
          DATE_PICKER_FORMAT: "YYYY-MM-DD",
          DATE_FORMAT_DATA_TABLE: "DD-MM-YYYY HH:mm:ss",
          HOUR_FORMAT: "HH:mm",
          COLOR_SCHEDULE_ONGOING: "#FCD5B5",
          COLOR_SCHEDULE_PENDING: "#F79646",
          MAX_DAY_SELECT: 90,
          GROUP_NAME: {
            "Indoor Show Group": "indoor_show_group",
            "Indoor Practice Group": "indoor_practice_group",
            "Outdoor Tennis Group": "outdoor_tennis_group",
            "Junior Group": "junior",
            "Rooftop Group": "rooftop_group",
          },
          STATUS_DISABLED_DATE_SCHEDULE: ['On-going', 'Completed', 'Pending'],
          HIGH_LIGHT_HOUR: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
          TIMEOUT_UPDATE_COURT: 10000,
          SUPER_ADMIN_ROLE: 'Superadmin',
          WEATHER_ICONS: {
            "01d": "clear-day",
            "01n": "clear-night",
            "02d": "partly-cloudy-day",
            "02n": "partly-cloudy-night",
            "03d": "cloudy",
            "03n": "cloudy",
            "04d": "cloudy",
            "04n": "cloudy",
            "09d": "rain",
            "09n": "rain",
            "10d": "rain",
            "10n": "rain",
            "11d": "wind",
            "11n": "wind",
            "13d": "snow",
            "13n": "snow",
            "50d": "fog",
            "50n": "fog"
          },
          SITE_STATUS_COLORS: {
            "normal": "#00b050",
            "alarm": "#ff0000",
            "offline": "#646464"
          },
          CURRENT_STATUS_COLORS: {
            "resolved": "#00B050",
            "wip": "#D19500",
            "new": "#1976d2"
          },
          ASSET_STATUS_COLORS: {
            "normal": "#4A8521",
            "alarm": "#FF453A",
            "offline": "#646464"
          },
          ALARM_STATUS_COLORS: {
            "resolved": "#4A8521",
            "new": "#FF453A",
            "wip": "#121212"
          },
          LIST_CURRENCIES: [
            {"cc":"AED","symbol":"\u062f.\u0625;","name":"UAE dirham"},
            {"cc":"AFN","symbol":"Afs","name":"Afghan afghani"},
            {"cc":"ALL","symbol":"L","name":"Albanian lek"},
            {"cc":"AMD","symbol":"AMD","name":"Armenian dram"},
            {"cc":"ANG","symbol":"NA\u0192","name":"Netherlands Antillean gulden"},
            {"cc":"AOA","symbol":"Kz","name":"Angolan kwanza"},
            {"cc":"ARS","symbol":"$","name":"Argentine peso"},
            {"cc":"AUD","symbol":"$","name":"Australian dollar"},
            {"cc":"AWG","symbol":"\u0192","name":"Aruban florin"},
            {"cc":"AZN","symbol":"AZN","name":"Azerbaijani manat"},
            {"cc":"BAM","symbol":"KM","name":"Bosnia and Herzegovina konvertibilna marka"},
            {"cc":"BBD","symbol":"Bds$","name":"Barbadian dollar"},
            {"cc":"BDT","symbol":"\u09f3","name":"Bangladeshi taka"},
            {"cc":"BGN","symbol":"BGN","name":"Bulgarian lev"},
            {"cc":"BHD","symbol":".\u062f.\u0628","name":"Bahraini dinar"},
            {"cc":"BIF","symbol":"FBu","name":"Burundi franc"},
            {"cc":"BMD","symbol":"BD$","name":"Bermudian dollar"},
            {"cc":"BND","symbol":"B$","name":"Brunei dollar"},
            {"cc":"BOB","symbol":"Bs.","name":"Bolivian boliviano"},
            {"cc":"BRL","symbol":"R$","name":"Brazilian real"},
            {"cc":"BSD","symbol":"B$","name":"Bahamian dollar"},
            {"cc":"BTN","symbol":"Nu.","name":"Bhutanese ngultrum"},
            {"cc":"BWP","symbol":"P","name":"Botswana pula"},
            {"cc":"BYR","symbol":"Br","name":"Belarusian ruble"},
            {"cc":"BZD","symbol":"BZ$","name":"Belize dollar"},
            {"cc":"CAD","symbol":"$","name":"Canadian dollar"},
            {"cc":"CDF","symbol":"F","name":"Congolese franc"},
            {"cc":"CHF","symbol":"Fr.","name":"Swiss franc"},
            {"cc":"CLP","symbol":"$","name":"Chilean peso"},
            {"cc":"CNY","symbol":"\u00a5","name":"Chinese/Yuan renminbi"},
            {"cc":"COP","symbol":"Col$","name":"Colombian peso"},
            {"cc":"CRC","symbol":"\u20a1","name":"Costa Rican colon"},
            {"cc":"CUC","symbol":"$","name":"Cuban peso"},
            {"cc":"CVE","symbol":"Esc","name":"Cape Verdean escudo"},
            {"cc":"CZK","symbol":"K\u010d","name":"Czech koruna"},
            {"cc":"DJF","symbol":"Fdj","name":"Djiboutian franc"},
            {"cc":"DKK","symbol":"Kr","name":"Danish krone"},
            {"cc":"DOP","symbol":"RD$","name":"Dominican peso"},
            {"cc":"DZD","symbol":"\u062f.\u062c","name":"Algerian dinar"},
            {"cc":"EEK","symbol":"KR","name":"Estonian kroon"},
            {"cc":"EGP","symbol":"\u00a3","name":"Egyptian pound"},
            {"cc":"ERN","symbol":"Nfa","name":"Eritrean nakfa"},
            {"cc":"ETB","symbol":"Br","name":"Ethiopian birr"},
            {"cc":"EUR","symbol":"\u20ac","name":"European Euro"},
            {"cc":"FJD","symbol":"FJ$","name":"Fijian dollar"},
            {"cc":"FKP","symbol":"\u00a3","name":"Falkland Islands pound"},
            {"cc":"GBP","symbol":"\u00a3","name":"British pound"},
            {"cc":"GEL","symbol":"GEL","name":"Georgian lari"},
            {"cc":"GHS","symbol":"GH\u20b5","name":"Ghanaian cedi"},
            {"cc":"GIP","symbol":"\u00a3","name":"Gibraltar pound"},
            {"cc":"GMD","symbol":"D","name":"Gambian dalasi"},
            {"cc":"GNF","symbol":"FG","name":"Guinean franc"},
            {"cc":"GQE","symbol":"CFA","name":"Central African CFA franc"},
            {"cc":"GTQ","symbol":"Q","name":"Guatemalan quetzal"},
            {"cc":"GYD","symbol":"GY$","name":"Guyanese dollar"},
            {"cc":"HKD","symbol":"HK$","name":"Hong Kong dollar"},
            {"cc":"HNL","symbol":"L","name":"Honduran lempira"},
            {"cc":"HRK","symbol":"kn","name":"Croatian kuna"},
            {"cc":"HTG","symbol":"G","name":"Haitian gourde"},
            {"cc":"HUF","symbol":"Ft","name":"Hungarian forint"},
            {"cc":"IDR","symbol":"Rp","name":"Indonesian rupiah"},
            {"cc":"ILS","symbol":"\u20aa","name":"Israeli new sheqel"},
            {"cc":"INR","symbol":"\u20B9","name":"Indian rupee"},
            {"cc":"IQD","symbol":"\u062f.\u0639","name":"Iraqi dinar"},
            {"cc":"IRR","symbol":"IRR","name":"Iranian rial"},
            {"cc":"ISK","symbol":"kr","name":"Icelandic kr\u00f3na"},
            {"cc":"JMD","symbol":"J$","name":"Jamaican dollar"},
            {"cc":"JOD","symbol":"JOD","name":"Jordanian dinar"},
            {"cc":"JPY","symbol":"\u00a5","name":"Japanese yen"},
            {"cc":"KES","symbol":"KSh","name":"Kenyan shilling"},
            {"cc":"KGS","symbol":"\u0441\u043e\u043c","name":"Kyrgyzstani som"},
            {"cc":"KHR","symbol":"\u17db","name":"Cambodian riel"},
            {"cc":"KMF","symbol":"KMF","name":"Comorian franc"},
            {"cc":"KPW","symbol":"W","name":"North Korean won"},
            {"cc":"KRW","symbol":"W","name":"South Korean won"},
            {"cc":"KWD","symbol":"KWD","name":"Kuwaiti dinar"},
            {"cc":"KYD","symbol":"KY$","name":"Cayman Islands dollar"},
            {"cc":"KZT","symbol":"T","name":"Kazakhstani tenge"},
            {"cc":"LAK","symbol":"KN","name":"Lao kip"},
            {"cc":"LBP","symbol":"\u00a3","name":"Lebanese lira"},
            {"cc":"LKR","symbol":"Rs","name":"Sri Lankan rupee"},
            {"cc":"LRD","symbol":"L$","name":"Liberian dollar"},
            {"cc":"LSL","symbol":"M","name":"Lesotho loti"},
            {"cc":"LTL","symbol":"Lt","name":"Lithuanian litas"},
            {"cc":"LVL","symbol":"Ls","name":"Latvian lats"},
            {"cc":"LYD","symbol":"LD","name":"Libyan dinar"},
            {"cc":"MAD","symbol":"MAD","name":"Moroccan dirham"},
            {"cc":"MDL","symbol":"MDL","name":"Moldovan leu"},
            {"cc":"MGA","symbol":"FMG","name":"Malagasy ariary"},
            {"cc":"MKD","symbol":"MKD","name":"Macedonian denar"},
            {"cc":"MMK","symbol":"K","name":"Myanma kyat"},
            {"cc":"MNT","symbol":"\u20ae","name":"Mongolian tugrik"},
            {"cc":"MOP","symbol":"P","name":"Macanese pataca"},
            {"cc":"MRO","symbol":"UM","name":"Mauritanian ouguiya"},
            {"cc":"MUR","symbol":"Rs","name":"Mauritian rupee"},
            {"cc":"MVR","symbol":"Rf","name":"Maldivian rufiyaa"},
            {"cc":"MWK","symbol":"MK","name":"Malawian kwacha"},
            {"cc":"MXN","symbol":"$","name":"Mexican peso"},
            {"cc":"MYR","symbol":"RM","name":"Malaysian ringgit"},
            {"cc":"MZM","symbol":"MTn","name":"Mozambican metical"},
            {"cc":"NAD","symbol":"N$","name":"Namibian dollar"},
            {"cc":"NGN","symbol":"\u20a6","name":"Nigerian naira"},
            {"cc":"NIO","symbol":"C$","name":"Nicaraguan c\u00f3rdoba"},
            {"cc":"NOK","symbol":"kr","name":"Norwegian krone"},
            {"cc":"NPR","symbol":"NRs","name":"Nepalese rupee"},
            {"cc":"NZD","symbol":"NZ$","name":"New Zealand dollar"},
            {"cc":"OMR","symbol":"OMR","name":"Omani rial"},
            {"cc":"PAB","symbol":"B./","name":"Panamanian balboa"},
            {"cc":"PEN","symbol":"S/.","name":"Peruvian nuevo sol"},
            {"cc":"PGK","symbol":"K","name":"Papua New Guinean kina"},
            {"cc":"PHP","symbol":"\u20b1","name":"Philippine peso"},
            {"cc":"PKR","symbol":"Rs.","name":"Pakistani rupee"},
            {"cc":"PLN","symbol":"z\u0142","name":"Polish zloty"},
            {"cc":"PYG","symbol":"\u20b2","name":"Paraguayan guarani"},
            {"cc":"QAR","symbol":"QR","name":"Qatari riyal"},
            {"cc":"RON","symbol":"L","name":"Romanian leu"},
            {"cc":"RSD","symbol":"din.","name":"Serbian dinar"},
            {"cc":"RUB","symbol":"R","name":"Russian ruble"},
            {"cc":"SAR","symbol":"SR","name":"Saudi riyal"},
            {"cc":"SBD","symbol":"SI$","name":"Solomon Islands dollar"},
            {"cc":"SCR","symbol":"SR","name":"Seychellois rupee"},
            {"cc":"SDG","symbol":"SDG","name":"Sudanese pound"},
            {"cc":"SEK","symbol":"kr","name":"Swedish krona"},
            {"cc":"SGD","symbol":"S$","name":"Singapore dollar"},
            {"cc":"SHP","symbol":"\u00a3","name":"Saint Helena pound"},
            {"cc":"SLL","symbol":"Le","name":"Sierra Leonean leone"},
            {"cc":"SOS","symbol":"Sh.","name":"Somali shilling"},
            {"cc":"SRD","symbol":"$","name":"Surinamese dollar"},
            {"cc":"SYP","symbol":"LS","name":"Syrian pound"},
            {"cc":"SZL","symbol":"E","name":"Swazi lilangeni"},
            {"cc":"THB","symbol":"\u0e3f","name":"Thai baht"},
            {"cc":"TJS","symbol":"TJS","name":"Tajikistani somoni"},
            {"cc":"TMT","symbol":"m","name":"Turkmen manat"},
            {"cc":"TND","symbol":"DT","name":"Tunisian dinar"},
            {"cc":"TRY","symbol":"TRY","name":"Turkish new lira"},
            {"cc":"TTD","symbol":"TT$","name":"Trinidad and Tobago dollar"},
            {"cc":"TWD","symbol":"NT$","name":"New Taiwan dollar"},
            {"cc":"TZS","symbol":"TZS","name":"Tanzanian shilling"},
            {"cc":"UAH","symbol":"UAH","name":"Ukrainian hryvnia"},
            {"cc":"UGX","symbol":"USh","name":"Ugandan shilling"},
            {"cc":"USD","symbol":"US$","name":"United States dollar"},
            {"cc":"UYU","symbol":"$U","name":"Uruguayan peso"},
            {"cc":"UZS","symbol":"UZS","name":"Uzbekistani som"},
            {"cc":"VEB","symbol":"Bs","name":"Venezuelan bolivar"},
            {"cc":"VND","symbol":"\u20ab","name":"Vietnamese dong"},
            {"cc":"VUV","symbol":"VT","name":"Vanuatu vatu"},
            {"cc":"WST","symbol":"WS$","name":"Samoan tala"},
            {"cc":"XAF","symbol":"CFA","name":"Central African CFA franc"},
            {"cc":"XCD","symbol":"EC$","name":"East Caribbean dollar"},
            {"cc":"XDR","symbol":"SDR","name":"Special Drawing Rights"},
            {"cc":"XOF","symbol":"CFA","name":"West African CFA franc"},
            {"cc":"XPF","symbol":"F","name":"CFP franc"},
            {"cc":"YER","symbol":"YER","name":"Yemeni rial"},
            {"cc":"ZAR","symbol":"R","name":"South African rand"},
            {"cc":"ZMK","symbol":"ZK","name":"Zambian kwacha"},
            {"cc":"ZWR","symbol":"Z$","name":"Zimbabwean dollar"}
          ],
          WEB_SOCKET_URL: process.env.VUE_APP_WEB_SOCKET_URL,
        };
      },
      methods: {
        Empty: function (value) {
          return (
            value == undefined ||
            value == null ||
            value == "" ||
            value.length === 0 ||
            Object.keys(value).length === 0
          );
        },
        MOMENT: function (dateTime, format) {
          if (typeof format !== "undefined" && format) {
            return moment(dateTime).format(format);
          }
          return moment(dateTime);
        },
        NOW_DATE: function () {
          return moment();
        },
        formatDataTableDate(value) {
          if (value) {
            return moment(String(value)).format(this.DATE_FORMAT_DATA_TABLE)
          }
        },
        capitalizeFirstLetter (e) {
          return e.charAt(0).toUpperCase() + e.slice(1);
        },
        formatMoney(value) {
          return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        formatDecimalNumber(number) {
          if (number === null || number === undefined) {
            return null;
          }
          const numberString = number.toString();
          if (numberString.includes('.')) {
            const decimalPart = numberString.split('.')[1];
            if (decimalPart.length >= 3) {
              return parseFloat(number).toFixed(2);
            }
          }
          return number;
        },
        renderIcon(show, tag, classShow, classElse) {
          return `<${tag} class='${show ? classShow : classElse}'></${tag}>`;
        },
        convertEnergyValue(value) {
          if (value >= 1000000000) {
            return this.formatDecimalNumber(value / 1000000000);
          } else if (value >= 1000000) {
            return this.formatDecimalNumber(value / 1000000);
          } else if (value >= 1000) {
            return this.formatDecimalNumber(value / 1000);
          } else {
            return this.formatDecimalNumber(value);
          }
        },
        convertBahtCurrency(value) {
          if (value >= 1000000000000) {
            return this.formatDecimalNumber(value / 1000000000000);
          } else if (value >= 1000000000) {
            return this.formatDecimalNumber(value / 1000000000);
          } else if (value >= 1000000) {
            return this.formatDecimalNumber(value / 1000000);
          } else if (value >= 1000) {
            return this.formatDecimalNumber(value / 1000);
          } else {
            return this.formatDecimalNumber(value);
          }
        },
        convertCo2Value(value) {
          if (value >= 1000000000000) {
            return this.formatDecimalNumber(value / 1000000000000);
          } else if (value >= 1000000000) {
            return this.formatDecimalNumber(value / 1000000000);
          } else if (value >= 1000000) {
            return this.formatDecimalNumber(value / 1000000);
          } else if (value >= 1000) {
            return this.formatDecimalNumber(value / 1000);
          } else {
            return this.formatDecimalNumber(value);
          }
        },
        convertPvValue(value) {
          if (value >= 1000000) {
            return this.formatDecimalNumber(value / 1000000);
          } else if (value >= 1000) {
            return this.formatDecimalNumber(value / 1000);
          } else {
            return this.formatDecimalNumber(value);
          }
        },
        convertEnergyUnit(value) {
          if (value >= 1000000000) {
            return "TWh";
          } else if (value >= 1000000) {
            return "GWh";
          } else if (value >= 1000) {
            return "MWh";
          } else {
            return "kWh";
          }
        },
        convertCountingUnit(value) {
          if (value >= 1000000000000) {
            return "tr";
          } else if (value >= 1000000000) {
            return "b";
          } else if (value >= 1000000) {
            return "mil";
          } else if (value >= 1000) {
            return "k";
          } else {
            return "";
          }
        },
        convertCo2Unit(value) {
          if (value >= 1000000000000) {
            return "gt";
          } else if (value >= 1000000000) {
            return "mt";
          } else if (value >= 1000000) {
            return "kt";
          } else if (value >= 1000) {
            return "ton";
          } else {
            return "kg";
          }
        },
        convertPvUnit(value) {
          if (value >= 1000000) {
            return "GWp";
          } else if (value >= 1000) {
            return "MWp";
          } else {
            return "kW";
          }
        },
        formatNumber(number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    });
  },
};

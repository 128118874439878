import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import global from "@/plugins/global.js";
import TurnOffAutocomplete from 'vue-turn-off-autocomplete';

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import BootstrapVue from "bootstrap-vue";
import * as VueGoogleMaps from 'vue2-google-maps';

import "../node_modules/bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";

Vue.use(BootstrapVue);
Vue.use(global);
Vue.use(TurnOffAutocomplete);
Vue.use(VueGoogleMaps, {
  load: {
    region: "TH",
    key: process.env.VUE_APP_KEY_GOOGLE_MAP,
    libraries: "places,drawing,visualization",
    v: 3.38
  },
  installComponents: true,
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

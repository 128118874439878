import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import { noAuthRoutes } from '../constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'MultipleSitesDashboard',
    component: () => import('../views/MultipleSitesDashboard.vue')
  },
  {
    path: '/single-site/:id',
    name: 'SingleSiteDashboard',
    component: () => import('../views/single-site-dashboard/SingleSiteDashboard.vue')
  },
  {
    path: '/data-analytic/:id',
    name: 'DataAnalyticDashboard',
    component: () => import('../views/data-analytic-dashboard/DataAnalyticDashboard.vue')
  },
  {
    path: '/data-saving/:id',
    name: 'DataSavingDashboard',
    component: () => import('../views/data-saving-dashboard/DataSavingDashboard.vue')
  },
  {
    path: '/asset-management/:id',
    name: 'AssetManagement',
    component: () => import('../views/asset-management/AssetManagement.vue')
  },
  {
    path: '/alarm-management/:id',
    name: 'AlarmManagement',
    component: () => import('../views/alarm-management/AlarmManagement.vue')
  },
  {
    path: '/inventory-management',
    name: 'InventoryManagement',
    component: () => import('../views/inventory-management/InventoryManagement.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/users/Users.vue'),
    children: [
      {
        path: 'user-group-management',
        name: 'UserGroupManagement',
        component: () => import('../views/user-group-management/UserGroupManagement.vue')
      },
      {
        path: 'user-management',
        name: 'UserManagement',
        component: () => import('../views/user-management/UserManagement.vue')
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (noAuthRoutes.findIndex(el => el == to.name) < 0 && !store.getters.isLoggedIn) {
    next({ name: 'Login' })
  } else {
    if(from.path == '/login' && to.path == '/' && (!store.getters.accessibleRoutes?.multi_site?.view || store.getters.user?.user_site?.length === 1)) {
      let getSiteId = store.getters.user?.user_site?.length ? store.getters.user.user_site[0].site_id : null;
      if(getSiteId && store.getters.accessibleRoutes.single_site.view) {
        store.commit('setSiteId', getSiteId);
        localStorage.setItem('selectedSiteId', store.getters.siteId);
        router.push({ path: '/single-site/' + getSiteId });
        if (!store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', true);
        }
      } else {
        router.push({ path: '*' });
      }
    } else {
      if (['/', '/inventory-management', '/users/user-management', '/users/user-group-management'].includes(to.path)) {
        if (store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', false);
        }
        // Check permissions to view screens
        if (['UserManagement', 'UserGroupManagement'].includes(to.name) && !store.getters.accessibleRoutes?.user?.view) {
          next({ name: 'Login' })
        } 
        if (['InventoryManagement'].includes(to.name) && !store.getters.accessibleRoutes?.inventory?.view) {
          next({ name: 'Login' })
        }
      } else {
        if (!store.getters.isHeaderMenuFul) {
          store.commit('setHeaderMenu', true);
        }
        // Check permissions to view screens
        if (['SingleSiteDashboard'].includes(to.name) && !store.getters.accessibleRoutes?.single_site?.view) {
          next({ name: 'Login' })
        } 
        if (['DataAnalyticDashboard', 'DataSavingDashboard'].includes(to.name) && !store.getters.accessibleRoutes?.data_analytic?.view) {
          next({ name: 'Login' })
        } 
        if (['AssetManagement'].includes(to.name) && !store.getters.accessibleRoutes?.asset?.view) {
          next({ name: 'Login' })
        }
        if (['AlarmManagement'].includes(to.name) && !store.getters.accessibleRoutes?.alarm?.view) {
          next({ name: 'Login' })
        }
      }
      next();
    }
  }
})

export default router

import HTTP_SERVICE from './http-service';

export default {
    getListUserGroups(params) {
        return HTTP_SERVICE.get('/user-groups' + (params ?? ''));
    },

    storeUserGroup(data) {
        return HTTP_SERVICE.post('/user-groups', data);
    },

    getUserGroup(id) {
        return HTTP_SERVICE.get(`/user-groups/${id}`);
    },

    updateUserGroup(id, data) {
        return HTTP_SERVICE.put(`/user-groups/${id}`, data);
    },

    deleteUserGroup(id) {
        return HTTP_SERVICE.delete(`/user-groups/${id}`);
    },
}
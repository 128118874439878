import HTTP_SERVICE from './http-service';

export default {
    getListSites(params) {
        return HTTP_SERVICE.get('/sites' + (params ?? ''));
    },
    storeSite(data) {
        HTTP_SERVICE.instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
        return HTTP_SERVICE.post('/sites', data);
    },
    deleteSite(id) {
        return HTTP_SERVICE.delete(`/sites/${id}`);
    },
    getListAssignInstallers() {
        return HTTP_SERVICE.get('/get-list-installer');
    },
    getSite(id) {
        return HTTP_SERVICE.get(`/sites/${id}`);
    },
    updateSite(id, data) {
        HTTP_SERVICE.instance.defaults.headers.common["Content-Type"] = "multipart/form-data";
        return HTTP_SERVICE.post(`/sites/${id}`, data);
    },
}
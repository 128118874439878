import HTTP_SERVICE from './http-service';
import axios from 'axios';

export default {
    getSingleSite(id) {
        return HTTP_SERVICE.get(`/single-site/${id}`);
    },
    getDataWeather(lat, long) {
        return axios.get(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${long}&appid=9566067bf143b4c8c83bdc1dfbb389f7`);
    },
}